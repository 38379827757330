// src/App.js
import React from 'react';
import ComingSoon from './pages/ComingSoon/ComingSoon';

function App() {
  return (
    <div className="App">
      <ComingSoon />
    </div>
  );
}

export default App;